// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-conditions-generales-de-vente-dutilisation-js": () => import("/Users/gregoire/Google Drive/006 - Freelance/clients/StartMyStory/Landing-page-v2/packages/landing-gatsby/src/pages/conditions-generales-de-vente-dutilisation.js" /* webpackChunkName: "component---src-pages-conditions-generales-de-vente-dutilisation-js" */),
  "component---src-pages-index-js": () => import("/Users/gregoire/Google Drive/006 - Freelance/clients/StartMyStory/Landing-page-v2/packages/landing-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("/Users/gregoire/Google Drive/006 - Freelance/clients/StartMyStory/Landing-page-v2/packages/landing-gatsby/src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/gregoire/Google Drive/006 - Freelance/clients/StartMyStory/Landing-page-v2/packages/landing-gatsby/.cache/data.json")

